
            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";

.wrapper {
    padding: 75px 0;
    @apply mx-auto px-5 lg:max-w-7xl grid grid-cols-1 lg:grid-cols-3 gap-20;

    h2 {
        @apply text-lg mb-4;
        font-style: normal;
        font-weight: 700;
        line-height: 38px;
        letter-spacing: 0em;
        text-align: left;
        @include md {
            @apply text-2xl;
        }
    }
}
.item {
    @apply relative flex flex-col items-center justify-start text-center p-10 max-w-sm mx-auto;
    background-color: #f9f9f9;
    gap: 10px;
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.1);
    div {
        @apply flex flex-col items-center justify-center gap-4;
        h3 {
            @apply text-xl font-bold;
        }
    }
    p {
        color: #585858;
    }
    .index {
        position: absolute;
        width: 60px;
        height: 60px;
        @apply flex text-xl items-center justify-center;
        top: 0;
        left: 0;
    }
}
